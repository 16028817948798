import Alpine from "alpinejs"

Alpine.data('PrivacyDialog', function() {
  return {
    close() {
      this.$store.privacy.showDialog = false;
      this.$store.privacy.saveSetting('dialog_show', false);
      this.$store.privacy.injectOptinCodes();
    },

    privacy() {
      return this.$store.privacy;
    },

    saveCurrentSelection() {
      this.privacy().saveAll();
      this.close();
    },

    acceptAll() {
      this.privacy().saveAll(true);
      this.close();
    },

  }
})
