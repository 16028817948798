import Alpine from "alpinejs"

// get('site')
// .then(response => response.json())
// .then(response => {

//   console.log(response.data);

// })
// .catch(error => {
//   console.error('Could not load ajax data.');
// });

export default async function get(query, options = {}) {
  let defaults = {
    method: "GET",
  }

  const response = await fetch(query, Object.assign(defaults, options))

  const text = await response.text()

  let json

  try {
    json = JSON.parse(text)
  } catch (e) {
    Alpine.$store("dialog").error(
      "Fehler",
      "JSON konnte nicht geparsed werden: " +
        text +
        " " +
        e +
        " Bitte wenden Sie sich direkt an uns."
    )
    throw json
  }

  // check for the server response code
  if (response.status < 200 || response.status > 299) {
    throw json
  }

  // look for an error status
  if (json.status && json.status === "error") {
    throw json
  }

  let data = json

  return data

  // .then((response) => {
  //   if (!response.ok) {
  //     // create error object and reject if not a 2xx response code
  //     let err = new Error("HTTP status code: " + response.status)
  //     err.response = response
  //     err.status = response.status
  //     throw err
  //   }
  //   return response
  // })
}
