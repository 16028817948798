import Alpine from "alpinejs"
import get from "utils/get"

Alpine.data(
  "form",
  (
    formdata = [],
    errors = [],
    successMessage = "Das Formular wurde erfolgreich gesendet.",
    errorMessage = "Es ist leider folgender Fehler aufgetreten:"
  ) => ({
    ...formdata,

    errors: errors,
    hasErrors: false,
    loading: false,

    successMessage: successMessage,
    errorMessage: errorMessage,

    setFieldError(field, data) {
      if (Object.keys(data).includes(field)) {
        this.errors[field] = data[field]
      } else {
        this.errors = this.errors.filter(item => item !== field)
      }

      this.hasErrors = Object.keys(this.errors).length > 0
    },

    validate(e) {
      var $this = this
      var $field = e.target
      var $form = $field.form

      get("/form/validate/" + this.pageid, {
        method: $form.method,
        body: new FormData($form),
      }).then(function (response) {
        $this.setFieldError($field.name, response)
      })
    },

    submit(e) {
      if (this.loading) {
        e.preventDefault();
        return false;
      }
      this.loading = true;

      var $this = this
      var $form = e.target

      get("/form/submit/" + this.pageid, {
        method: $form.method,
        body: new FormData($form),
      })
        .then(function () {
          // $this.errors = [];
          $this.$store.dialog.success("Gesendet", $this.successMessage)
          $form.reset();
          $this.loading = false;
        })
        .catch(function (data) {
          var keys = Object.keys(data)
          var first = keys[0]
          if (data.message) {
            $this.$store.dialog.error(
              "Fehler",
              $this.errorMessage +
                " " +
                data.message +
                " Bitte wenden Sie sich direkt an uns."
            )
          } else if (first.includes("Uniform")) {
            $this.$store.dialog.error(
              "Fehler",
              $this.errorMessage +
                " " +
                data[keys] +
                " Bitte wenden Sie sich direkt an uns."
            )
          } else {
            $this.errors = data
          }
          $this.loading = false;
        })
    },
  })
)
