
// Polyfills
import 'es6-promise/auto';  // needed for polyfill loading

import(
  /* webpackChunkName: "polyfills" */
  'respimage'
);
// import 'whatwg-fetch';
// import 'cq-prolyfill';

import Alpine from 'alpinejs'
window.Alpine = Alpine



import './utilities/instantpage';

// Components

import './patternlab/patterns/blocks/privacy/privacy';
import './patternlab/patterns/blocks/nav/nav';
import './patternlab/patterns/blocks/video/video';
import './patternlab/patterns/blocks/slider/slider';
import './patternlab/patterns/blocks/map/map';
import './patternlab/patterns/blocks/dialog/dialog';
import './patternlab/patterns/blocks/flipbook/flipbook';
// import './patternlab/patterns/form/dropzone/dropzone';



// Pagetypes

import './pagetypes/form/form';

// Interactive

// import './patternlab/interactive/aos/aos.js';
import './patternlab/interactive/lightbox/lightbox.js';
// import './patternlab/interactive/tooltip/tooltip.js';
// import './patternlab/interactive/countup/countup.js';

Alpine.start()
