const $ = (selector, scope = document) => {
  return scope.querySelector(selector);
};

const $$ = (selector, scope = document) => {
  return [].slice.call(scope.querySelectorAll(selector));
};

window.$ = $;
window.$$ = $$;
export {$, $$};
