import ready from "utils/ready"
import { $$ } from "utils/selector"
import SwiperCore, { Pagination, Autoplay } from "swiper/core"
import "swiper/swiper-bundle.css"
import "./slider.css"

SwiperCore.use([Pagination, Autoplay])

ready().then(() => {
  const className = ".js-swiper"
  const elements = $$(className)

  if (elements.length > 0) {
    elements.forEach((element) => {
      let config = {
        autoHeight: true,
        dynamicBullets: true,
        effect: "slide",

        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      }
      let data = element.dataset
      for (let i in data) {
        let value = data[i]
        if (i == "slidesperview") {
          config["slidesPerView"] = parseInt(value)
        } else if (i == "loop") {
          config["loop"] = value == "true"
        } else if (i == "transitionduration") {
          config["speed"] = parseInt(value)
        } else if (i == "autoplayduration") {
          if (value != "0") {
            config["autoplay"] = { delay: value }
          }
        } else {
          config[i] = value
        }
      }

      new SwiperCore(element, config)
    })
  }
})
