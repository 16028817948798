import Alpine from "alpinejs"

Alpine.store("dialog", {
  show: false,
  type: "success", // success | error
  title: "Titel",
  text: "Text",
  btn: "zurück",

  success(
    title = "Erfolg",
    text = "Vorgang erfolgreich abgeschlossen.",
    btn = "Schließen"
  ) {
    this.type = "success"
    this.title = title
    this.text = text
    this.btn = btn
    this.show = true
  },

  error(
    title = "Fehler",
    text = "Der Vorgang konnte leider nicht abgeschlossen werden.",
    btn = "Schließen"
  ) {
    this.type = "error"
    this.title = title
    this.text = text
    this.btn = btn
    this.show = true
  },

  close() {
    this.show = false
  },
})
