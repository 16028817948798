import Alpine from "alpinejs"
import './video.css'

Alpine.data("Video", function (type) {
  return {
    type: type,
    initialized: false,

    init() {
      if (type == "file") {
        this.initPlayer()
      } else {
        if (this.$store.privacy.settings[type]) {
          this.initPlayer()
        }

        this.$watch("$store.privacy.settings." + type, () => {
          this.initPlayer()
        })
      }
    },

    initPlayer() {
      var element = this.$el

      if (!this.initialized) {
        Promise.all([
          import(
            /* webpackChunkName: "plyr" */
            "plyr/dist/plyr.js"
          ),
          import(
            /* webpackChunkName: "plyr" */
            "plyr/dist/plyr.css"
          ),
        ]).then((modules) => {
          const Plyr = modules[0].default
          new Plyr(element)
          this.initialized = true
        })
      }
    },
  }
})
