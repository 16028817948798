import Alpine from 'alpinejs'
import Cookies from "js-cookie"
import isLocalUrl from "utils/is-local-url"
import setInnerHTML from "utils/set-inner-html"
import "./dialog/dialog"

Alpine.store("privacy", {
  cookieName: "privacy",
  showDialog: false,
  showSettingToggles: false,
  siteSettings: [],
  settings: {},

  init: function () {

    var sitePrivacySettings = window.sitePrivacySettings
    // determine if the dialog should be shown: show if new user or not consented cookie
    if (sitePrivacySettings != null) {
      let cookie = Cookies.get(this.cookieName + "_" + "dialog_show")
      this.showDialog = cookie == undefined ? true : cookie == "true"
    }

    // load user selection
    let siteSettings = []
    for (const category in sitePrivacySettings) {
      let settings = sitePrivacySettings[category]

      for (const key in settings) {
        if (settings.hasOwnProperty(key)) {
          this.settings[key] = this.loadSetting(key)
          sitePrivacySettings[category][key]["category"] = category
        }
      }

      siteSettings = { ...siteSettings, ...sitePrivacySettings[category] }
    }
    this.siteSettings = siteSettings

    this.injectOptinCodes()
    this.injectFallbackCodes()

    Alpine.effect(() => {
      Object.keys(this.settings).forEach((key) => {
        let privacyStore = Alpine.store('privacy')
        let settings = Alpine.store('privacy')['settings']
        let value = settings[key]

        if (value == false) {
          let optoutcode = privacyStore.siteSettings[key].optoutcode
          privacyStore.injectCode(optoutcode)
        }

        privacyStore.saveSetting(key, value)
      });
    })

  },

  injectOptinCodes: function () {
    for (const key in this.settings) {
      if (
        this.settings.hasOwnProperty(key) &&
        this.siteSettings.hasOwnProperty(key)
      ) {
        // inject code if the user consented on every page load
        if (
          this.isServiceAllowed(key) &&
          ["statistical", "marketing"].includes(
            this.siteSettings[key]["category"]
          )
        ) {
          this.injectCode(this.siteSettings[key].optincode)
        }
      }
    }
  },

  injectFallbackCodes: function () {
    for (const key in this.settings) {
      if (
        this.settings.hasOwnProperty(key) &&
        this.siteSettings.hasOwnProperty(key)
      ) {
        if (
          ["statistical", "marketing"].includes(
            this.siteSettings[key]["category"]
          )
        ) {
          this.injectCode(this.siteSettings[key].fallbackcode)
        }
      }
    }
  },

  injectCode: function (code) {
    if (code != "" && code != undefined && this.isInjectionAllowed()) {
      let container = document.createElement("div")
      document.body.append(container)
      setInnerHTML(container, code)
    }
  },

  isInjectionAllowed: function () {
    return !isLocalUrl()
  },

  openDialog: function () {
    this.showDialog = true
    this.showSettingToggles = true
  },

  acceptService: function (id) {
    this.settings[id] = true
  },

  isServiceAllowed: function (id) {
    return this.settings[id] == undefined ? false : this.settings[id]
  },

  saveAll: function (val) {
    for (const key in this.settings) {
      if (this.settings.hasOwnProperty(key) && key != "__watchers") {
        this.settings[key] = val == undefined ? this.settings[key] : val
      }
    }
  },
  saveSetting: function (id, val) {
    Cookies.set(this.cookieName + "_" + id, val, { expires: 730 })
  },
  loadSetting: function (id) {
    let cookie = Cookies.get(this.cookieName + "_" + id)

    // show dialog if setting gets loaded which is not currently selected by the user
    if (cookie == undefined) {
      this.showDialog = true
    }

    return cookie == "true"
  },
})
